import {
  FETCH_VIDEOS_REQUEST,
  FETCH_VIDEOS_SUCCESS,
  FETCH_VIDEOS_FAILURE,
  VIDEO_PLAYLIST,
  SHOW_EXIT_POPUP,
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_SUCCESS,
  FETCH_TOKEN_FAILURE,
  STORE_USER,
  SET_HERO_IMAGE,
  SELECT_TV_SHOW,
  CLEAR_DATA,
  IS_MAIN_APP,
} from "../actionTypes";

const initialState = {
  videos: null,
  errors: [],
  loading: false,
  playlist: [],
  showExitPopUp: false,
  user: null,
  tokenLoading: false,
  tokenError: null,
  token: null,
  heroImage: "",
  selectedTvShow: null,
  isMainApp: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOKEN_REQUEST:
      return { ...state, tokenLoading: true };
    case IS_MAIN_APP:
      return { ...state, isMainApp: action.payload };
    case FETCH_TOKEN_SUCCESS: {
      return {
        ...state,
        tokenLoading: false,
        token: action.payload,
        errors: [],
      };
    }
    case FETCH_TOKEN_FAILURE:
      return { ...state, tokenLoading: false, tokenError: action.errors };
    case STORE_USER:
      return { ...state, user: action.payload };
    case FETCH_VIDEOS_REQUEST:
      return { ...state, loading: true };
    case FETCH_VIDEOS_SUCCESS: {
      return { ...state, loading: false, videos: action.payload, errors: [] };
    }
    case FETCH_VIDEOS_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case VIDEO_PLAYLIST:
      return { ...state, playlist: action.payload };
    case SHOW_EXIT_POPUP:
      return { ...state, showExitPopUp: action.payload };
    case SET_HERO_IMAGE:
      return { ...state, heroImage: action.payload };
    case SELECT_TV_SHOW:
      return { ...state, selectedTvShow: action.payload };
    case CLEAR_DATA:
      return {
        ...state,
        videos: null,
        errors: [],
        loading: false,
        playlist: [],
        showExitPopUp: false,
        user: null,
        tokenLoading: false,
        tokenError: null,
        token: null,
        heroImage: "",
        selectedTvShow: null,
      };
    default:
      return state;
  }
};

export default reducer;
