import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  playVideo,
  handleExitPopupVisibility,
  setHeroImage,
  selectTvShow,
} from "../store/actions";
import { useHistory } from "react-router-dom";
import { LEFT, RIGHT, UP, DOWN, ENTER, BACK } from "../constants/keyCodes";
import loader from "../assets/images/loader.svg";

const VideoItem = ({
  videos,
  name,
  type,
  isCurrentPlaylist,
  receivedFocusFromMenu,
  totalVideo,
  id,
  perPageVideo,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef(null);
  const _page = useRef(1);

  const [current, setCurrent] = useState(0);
  const [gotFocus, setGotFocus] = useState(false);
  const [videolist, setVideoList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setVideoList(videos);
  }, [videos]);

  useEffect(() => {
    if (receivedFocusFromMenu && isCurrentPlaylist && ref.current) {
      setTimeout(() => {
        ref.current.focus();
      }, 100);
    }
  }, [isCurrentPlaylist, receivedFocusFromMenu]);

  useEffect(() => {
    if (gotFocus) {
      const x = [];
      if (type === "TS")
        for (const key in videolist) {
          if (Object.hasOwnProperty.call(videolist, key)) {
            const element = videolist[key];
            x.push(element[Object.keys(element)[0]].video);
          }
        }

      const heroImage =
        type === "TS"
          ? x[current] && x[current][0].poster
          : videolist[current]?.poster;

      dispatch(setHeroImage(heroImage));
    }
  }, [current, gotFocus]);

  useEffect(() => {
    if (gotFocus) {
      window.onkeydown = (e) => {
        e.preventDefault();

        switch (e.keyCode) {
          case ENTER:
            window.onkeydown = null;
            if (type === "TS") {
              const showName = Object.keys(videolist[current])[0];
              dispatch(selectTvShow(videolist[current]));
              history.push(`/tv-episodes/${showName}`);
            } else {
              dispatch(playVideo(videolist));
              history.push(
                `/video-player/${current}?totalVideo=${totalVideo}&perPageVideo=${perPageVideo}&type=${type}&id=${id}`
              );
            }

            break;
          case LEFT:
            let x = current - 1;
            if (x >= 0) setCurrent((prev) => prev - 1);
            else {
              window.onkeydown = null;
              document.getElementById("home").focus();
            }
            break;
          case RIGHT:
            let y = current + 1;
            if (y < videolist.length) {
              setCurrent((prev) => prev + 1);
            }
            break;
          case UP:
            let m = current - 4;
            if (m >= 0) setCurrent((prev) => prev - 4);
            else {
              window.onkeydown = null;
              document.getElementById("home").focus();
            }
            break;
          case DOWN:
            let n = current + 4;
            if (n < videolist.length) {
              setCurrent((prev) => prev + 4);
            } else {
              setCurrent(videolist.length - 1);
            }

            break;
          case BACK:
            // if (location.pathname.includes("/tv-episodes")) {
            //   history.goBack();
            // } else {
            // if (window.history.state !== "backhandler") {
            // window.onkeydown = null;
            dispatch(handleExitPopupVisibility(true));
            // document.getElementById("list-container").blur();
            // document.getElementById("exit").focus();
            // window.history.pushState("backhandler", null, null);
            // }
            // }
            break;
          default:
            break;
        }
      };
    }
    return () => {
      window.onkeydown = null;
    };
  }, [gotFocus, current, videolist]);

  useEffect(() => {
    if (
      totalVideo > 0 &&
      current > 0 &&
      totalVideo > videolist.length &&
      current > Math.floor(videolist.length - 4) &&
      !isLoading
    ) {
      fetchData();
    }
  }, [current]);

  const fetchData = async () => {
    // const token = JSON.parse(Cookies.get("authToken"));
    setLoading(true);
    const url = `https://api.nctvn.com/getShowChannel?page=${
      _page.current + 1
    }&name=${id}&per_page=20`;
    _page.current += 1;

    const response = await fetch(url, {
      method: "GET",
      // headers: {
      //   Authorization: `Bearer ${token.access_token}`,
      // },
    }).then((res) => res.json());

    console.log(response, name);
    setVideoList([...videolist, ...response?.shows[name].video]);

    const oldData = JSON.parse(localStorage.getItem(id + "_data"));
    let newData = {
      ...oldData,
      TS: [
        {
          [name]: {
            ...response?.shows[name],
            video: [...videolist, ...response?.shows[name].video],
          },
        },
      ],
    };
    localStorage.setItem(id + "_page", _page.current);
    localStorage.setItem(id + "_data", JSON.stringify(newData));

    setLoading(false);
  };

  const videoCarosel = videolist.map((item, index) => {
    return (
      <div className="inline-block h-full px-2" key={index}>
        <img
          src={
            type === "TS"
              ? item[Object.keys(item)[0]].total_videos &&
                item[Object.keys(item)[0]]?.video[0].poster
              : item.poster
          }
          alt=""
          className={`w-full h-56 inline-block rounded-md shadow-inner bg-primary ${
            current === index &&
            gotFocus &&
            "border-4 border-selectedItem shadow-2xl transition-all"
          } ${
            index < current &&
            Math.floor(index / 4) < Math.floor(current / 4) &&
            "hidden"
          }`}
          style={{ marginBottom: "1.25rem" }}
        />
      </div>
    );
  });

  return (
    <div className={``}>
      {/* <h4
        className="mt-4 mb-3  font-semibold pl-2"
        style={{ marginLeft: "6%", fontSize: "1.7rem" }}
      >
        {name}
      </h4> */}
      <div
        className="relative w-full outline-none grid grid-cols-4 gap-x-1"
        tabIndex={8}
        style={{ padding: "0 6%" }}
        // onKeyDown={handleKeyDown}
        ref={ref}
        onFocus={() => setGotFocus(true)}
        onBlur={() => setGotFocus(false)}
      >
        {videoCarosel}
      </div>
      {isLoading && (
        <img
          src={loader}
          className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-24"
          alt=""
          style={{ bottom: "2%" }}
        />
      )}
    </div>
  );
};

export default VideoItem;
