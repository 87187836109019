import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  LEFT,
  RIGHT,
  UP,
  DOWN,
  ENTER,
  BACK,
  PLAY_PAUSE,
  // PLAY,
  // PAUSE,
} from "../constants/keyCodes";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Play from "../assets/icons/play.svg";
import logo from "../assets/images/logo.png";
import ccLogo from "../assets/images/ccLogo.png";
import JRLogo from "../assets/images/JRLogo.png";
import ciaLogo from "../assets/images/ciaLogo.png";
import loader from "../assets/images/loader.svg";
import Cookies from "js-cookie";

function VideoPlayer() {
  const playlist = useSelector((state) => state.playlist);

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const playlistId = queryParams.get("id");
  const totalVideo = parseInt(queryParams.get("totalVideo"));
  const perPageVideo = parseInt(queryParams.get("perPageVideo"));
  const type = queryParams.get("type");

  const { id } = useParams();

  const videoref = useRef(null);
  const imgRef = useRef(null);
  const seekBtn = useRef(null);

  const [current, setCurrent] = useState(id);
  const [showDetails, setShowDetails] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [videolist, setVideoList] = useState(playlist);
  const [currentTime, setCurrentTime] = useState(0);

  let timer = useRef(null);
  let durationTimer = useRef(null);

  useEffect(() => {
    videoref?.current?.focus();

    durationTimer.current = setInterval(() => {
      if (videoref?.current) setCurrentTime(videoref.current.currentTime);
    }, 1000);

    return () => {
      clearInterval(durationTimer?.current);
      clearTimeout(timer?.current);
      videoref.current = null;
    };
  }, [videoref]);

  useEffect(() => {
    if (showDetails) {
      videoref?.current?.pause();
      imgRef?.current?.focus();
    } else videoref?.current?.play();
  }, [showDetails]);

  const handleKeyDown = (e) => {
    // window.showAndroidToast(e.which);
    switch (e.which) {
      case LEFT:
      case RIGHT:
      case UP:
      case DOWN:
        if (videoref.current?.currentTime > 0 && !videoref.current?.ended)
          showdetails();
        break;

      case ENTER:
      case PLAY_PAUSE:
        setShowDetails(true);
        break;

      case BACK:
        history.goBack();
        break;
      default:
        return;
    }
  };

  const handlePlayClick = (e) => {
    // window.showAndroidToast(e.which);
    switch (e.which) {
      case ENTER:
      case PLAY_PAUSE:
        // case PLAY:
        videoref.current.focus();
        setShowDetails(false);
        clearTimeout(timer.current);
        break;

      case DOWN:
        seekBtn.current.focus();
        showdetails();
        break;

      case BACK:
        history.goBack();
        break;

      default:
        return;
    }
  };

  const handleSeek = (e) => {
    const duration = videoref.current?.duration;
    switch (e.which) {
      case LEFT:
        let newTime = videoref.current.currentTime - 10;
        if (newTime < 0) newTime = 0;
        videoref.current.currentTime = newTime;
        setCurrentTime(videoref.current.currentTime);
        if (timer.current) clearTimeout(timer.current);
        // setShowDetails(true);

        timer.current = setTimeout(() => {
          setShowDetails(false);
          videoref.current.focus();
        }, 4000);
        break;
      case RIGHT:
        let _newTime = videoref.current.currentTime + 10;
        if (_newTime > duration) _newTime = duration;
        videoref.current.currentTime = _newTime;
        setCurrentTime(videoref.current.currentTime);
        setTimeout(() => {
          seekBtn?.current?.focus();
        }, 0);
        if (timer.current) clearTimeout(timer.current);
        // setShowDetails(true);

        timer.current = setTimeout(() => {
          setShowDetails(false);
          videoref.current.focus();
        }, 4000);
        break;
      case UP:
        imgRef.current.focus();
        showdetails();
        break;
      case ENTER:
        videoref.current.focus();
        setShowDetails(false);
        clearTimeout(timer.current);
        break;

      case BACK:
        history.goBack();
        break;
      default:
        return;
    }
  };

  const showdetails = () => {
    if (timer.current) clearTimeout(timer.current);
    setShowDetails(true);

    timer.current = setTimeout(() => {
      setShowDetails(false);
      videoref.current.focus();
    }, 4000);
  };

  const handleChangeVideo = () => {
    let x = +current + 1;
    if (x > videolist.length - 1) {
      x = 0;
    }

    setCurrent(x);
    videoref.current.load();
  };

  useEffect(() => {
    if (
      totalVideo > videolist.length &&
      current === Math.floor(videolist.length - 3)
    ) {
      fetchData();
    }
  }, [current]);

  const fetchData = async () => {
    const token = JSON.parse(Cookies.get("authToken"));

    const url = `https://api.nctvn.com/data?page=${Math.ceil(
      totalVideo / perPageVideo
    )}&type=${type}&id=${playlistId}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    }).then((res) => res.json());
    const x = [...videolist, ...response.content.video];
    setVideoList(x);
  };

  const getTime = (x) => {
    let min = Math.floor(x / 60);
    let sec = Math.ceil(x % 60);

    return `${min}:${sec}`;
  };

  let currentPosition = `${(currentTime / videoref?.current?.duration) * 100}%`;
  const appName = localStorage.getItem("appName");
  return (
    <div className="relative outline-none">
      <video
        className="h-screen w-screen outline-none"
        autoPlay
        muted={false}
        onKeyDown={handleKeyDown}
        tabIndex={1}
        ref={videoref}
        poster={videolist[current].poster}
        onEnded={handleChangeVideo}
        onCanPlay={() => {
          setIsVideoLoading(false);
        }}
      >
        <source src={videolist[current].video} type="video/mp4" />
      </video>

      {(showDetails || isVideoLoading) && (
        <div
          className="absolute top-0 left-0 w-screen h-screen p-20"
          style={{ background: "#111111bb" }}
        >
          <span
            className="text-white font-bold text-4xl block w-3/4"
            style={{ lineHeight: 1.25 }}
          >
            {videolist[current].name}
          </span>
          <span
            className="text-white font-bold text-2xl mt-10 block w-3/4"
            style={{ lineHeight: 1.25 }}
          >
            {videolist[current].description}
          </span>

          {isVideoLoading ? (
            <img
              src={loader}
              className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-20"
              alt=""
            />
          ) : (
            <img
              src={Play}
              alt="play"
              ref={imgRef}
              tabIndex={2}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-20 focus:outline-none focus:border-4 focus:border-gray-50 rounded-full shadow-2xl"
              onKeyDown={handlePlayClick}
            />
          )}
          {/* // <img
          //   src={Play}
          //   alt="play"
          //   ref={imgRef}
          //   tabIndex={2}
          //   className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-20 focus:outline-none"
          //   onKeyDown={handlePlayClick}
          // /> */}
        </div>
      )}
      <img
        src={
          appName
            ? appName === "J Raleigh Jones Show"
              ? JRLogo
              : appName === "Country Independent Artists"
              ? ciaLogo
              : ccLogo
            : logo
        }
        className="absolute bottom-10 right-14 w-48 h-auto opacity-50"
        alt=""
      />

      {showDetails && (
        <div className="absolute h-2 w-full bottom-14 left-0 right-0 px-20 rounded-full">
          <div className="bg-gray-500 rounded-full w-full h-2 relative">
            <div
              className="h-2 bg-activeIcon"
              style={{
                width: currentPosition,
              }}
            ></div>
            <div
              className="focus:h-4 focus:w-4 rounded-full bg-activeIcon absolute top-0 outline-none focus:border-2 focus:border-gray-50 -translate-x-1/2"
              style={{
                left: currentPosition,
                top: "-2px",
              }}
              ref={seekBtn}
              tabIndex={3}
              onKeyDown={handleSeek}
            ></div>
          </div>
          <p className="mt-2.5 space-x-1 text-xl">
            <span>{getTime(currentTime)}</span>
            <span>/</span>
            <span>{getTime(videoref.current.duration)}</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default VideoPlayer;
