export const FETCH_VIDEOS_REQUEST = "FETCH_VIDEOS_REQUEST";
export const FETCH_VIDEOS_SUCCESS = "FETCH_VIDEOS_SUCCESS";
export const FETCH_VIDEOS_FAILURE = "FETCH_VIDEOS_FAILURE";
export const VIDEO_PLAYLIST = "VIDEO_PLAYLIST";
export const SHOW_EXIT_POPUP = "SHOW_EXIT_POPUP";
export const FETCH_TOKEN_REQUEST = "FETCH_TOKEN_REQUEST";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_FAILURE = "FETCH_TOKEN_FAILURE";
export const STORE_USER = "STORE_USER";
export const SET_HERO_IMAGE = "SET_HERO_IMAGE";
export const SELECT_TV_SHOW = "SELECT_TV_SHOW";
export const CLEAR_DATA = "CLEAR_DATA";
export const IS_MAIN_APP = "IS_MAIN_APP";
