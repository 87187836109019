import React, { useEffect, useState } from "react";
import VideoItem from "../components/VideoItem";
import { useSelector } from "react-redux";

function Music() {
  const videos = useSelector((state) => state.videos);
  const heroImage = useSelector((state) => state.heroImage);

  const [data, setData] = useState([]);
  const [currentPlaylist, setCurrentPlaylist] = useState(0);
  const [gotFocus, setGotFocus] = useState(true);

  useEffect(() => {
    const list = [];
    if (videos) {
      videos.MU.filter((item) => {
        let x = Object.keys(item)[0];

        return item[x].total_videos > 0;
      }).map((item) => {
        let x = Object.keys(item)[0];

        const listItem = {
          type: "MU",
          name: x,
          ...item[x],
        };
        list.push(listItem);
      });

      setData(list);
    }
  }, [videos]);

  const nextPlaylist = () => {
    let x = currentPlaylist + 1;
    if (x < data.length) setCurrentPlaylist((prev) => prev + 1);
  };

  const previousPlaylist = () => {
    let x = currentPlaylist - 1;
    if (x < 0) document.getElementById("home").focus();
    else setCurrentPlaylist((prev) => prev - 1);
  };

  let bottom = currentPlaylist * 18.5 + 6;
  return (
    <div className="w-full h-screen pb-11 overflow-hidden ">
      <div className="relative">
        <img src={heroImage} alt="" className="w-full h-hero -mb-40 z-0" />

        <div className="absolute w-full h-full top-0 bg-gradient-to-t from-black via-transparent to-black"></div>
      </div>

      <div
        className="relative focus:outline-none border-0 "
        id="list-container"
        onFocus={() => {
          setGotFocus(true);
        }}
        onBlur={() => {
          setGotFocus(false);
        }}
        tabIndex={1}
      >
        {data.map((item, idx) => {
          bottom -= 18.5;
          return (
            <div
              className={`absolute w-full focus:outline-none border-0   ${
                idx < currentPlaylist && "opacity-0"
              }`}
              style={{ bottom: `${bottom}rem` }}
              key={idx}
            >
              <VideoItem
                videos={item.video}
                name={item.name}
                type={item.type}
                description={item.description}
                id={item.id}
                perPageVideo={item.per_page}
                totalVideo={item.total_videos}
                isCurrentPlaylist={currentPlaylist === idx}
                nextPlaylist={nextPlaylist}
                previousPlaylist={previousPlaylist}
                receivedFocusFromMenu={gotFocus}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Music;
