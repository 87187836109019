import React, { useState } from "react";
import VideoItemGrid from "../components/VideoItemGrid";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

function IndividualApp() {
  const videos = useSelector((state) => state.videos);
  const heroImage = useSelector((state) => state.heroImage);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showName = queryParams.get("menu");
  const [gotFocus, setGotFocus] = useState(true);

  const videoList = videos.shows[showName];

  let queryName = "";
  if (showName === "J Raleigh Jones Show") queryName = "jrj";
  else if (showName === "Country Independent Artists") queryName = "cia";
  else if (showName === "Cash Creek TV") queryName = "cash";

  return (
    <div
      className="w-full h-screen overflow-hidden "
      id="list-container"
      onFocus={() => {
        setGotFocus(true);
      }}
      onBlur={() => {
        setGotFocus(false);
      }}
      tabIndex={1}
    >
      <div className="relative">
        <img
          src={heroImage}
          alt=""
          className="w-full mb-5 z-0"
          style={{ maxHeight: "73vh" }}
        />

        <div className="absolute w-full h-full top-0 bg-gradient-to-t from-black via-transparent to-black">
          <p
            className=" text-5xl font-bold text-white mt-20"
            style={{ marginLeft: "7%" }}
          >
            {showName}
          </p>
        </div>
      </div>

      <div
        className="absolute focus:outline-none border-0 w-full overflow-hidden"
        style={{ top: "54vh", bottom: 0 }}
        id="list-container"
        onFocus={() => {
          setGotFocus(true);
        }}
        onBlur={() => {
          setGotFocus(false);
        }}
        tabIndex={1}
      >
        <div>
          <VideoItemGrid
            videos={videoList.video}
            type={"episodes"}
            name={showName}
            totalVideo={videoList.total_videos}
            description={videoList.description}
            isCurrentPlaylist={true}
            id={queryName}
            perPageVideo={videoList.per_page}
            receivedFocusFromMenu={gotFocus}
          />
        </div>
      </div>
    </div>
  );
}

export default IndividualApp;
