import React, { useState, useEffect } from "react";
import VideoItem from "../components/VideoItem";
import VideoItemGrid from "../components/VideoItemGrid";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

function TvShowSeason() {
  const { name } = useParams();
  const tvShow = useSelector((state) => state.selectedTvShow);
  const heroImage = useSelector((state) => state.heroImage);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const layout = queryParams.get("layout");
  const showId = queryParams.get("showId");

  const [gotFocus, setGotFocus] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  return (
    <div className="w-full h-screen overflow-hidden">
      <div className="relative">
        <img
          src={heroImage}
          alt=""
          className="w-full mb-5 z-0"
          style={{ maxHeight: "73vh" }}
        />

        <div className="absolute w-full h-full top-0 bg-gradient-to-t from-black via-transparent to-black">
          <p
            className=" text-5xl font-bold text-white mt-20"
            style={{ marginLeft: "7%" }}
          >
            {`Welcome to ${name}`}
          </p>
          <p
            className="font-normal text-2xl text-white mt-6"
            style={{ marginLeft: "7%" }}
          >
            Select an Episode from list below and Start Enjoying
          </p>
        </div>
      </div>

      <div
        className="absolute focus:outline-none border-0 w-full overflow-hidden"
        style={{ top: "54vh", bottom: 0 }}
        id="list-container"
        onFocus={() => {
          setGotFocus(true);
        }}
        onBlur={() => {
          setGotFocus(false);
        }}
        tabIndex={1}
      >
        <div>
          {layout ? (
            <VideoItemGrid
              videos={tvShow[name].video}
              type={"episodes"}
              name={name}
              totalVideo={tvShow[name].total_videos}
              description={tvShow[name].description}
              isCurrentPlaylist={true}
              id={showId}
              perPageVideo={tvShow[name].per_page}
              nextPlaylist={() => {}}
              previousPlaylist={() => {}}
              receivedFocusFromMenu={gotFocus}
            />
          ) : (
            <VideoItem
              videos={tvShow[name].video}
              name={"Episodes"}
              type={"episodes"}
              totalVideo={tvShow[name].total_videos}
              description={tvShow[name].description}
              isCurrentPlaylist={true}
              id={tvShow[name].id}
              perPageVideo={tvShow[name].per_page}
              nextPlaylist={() => {}}
              previousPlaylist={() => {}}
              receivedFocusFromMenu={gotFocus}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TvShowSeason;
