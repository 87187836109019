import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

import reducer from "./store/reducer";

import Layout from "./components/Layout";
import Home from "./pages/Home";
import SplashScreen from "./pages/SplashScreen";
import VideoPlayer from "./pages/VideoPlayer";
import TvShowSeason from "./pages/TvShowSeason";
import Pair from "./pages/Pair";
import Music from "./pages/Music";
import TvShows from "./pages/TvShows";
import Account from "./pages/Account";
import LiveEvents from "./pages/LiveEvents";
import IndividualApp from "./pages/IndividualApp";

function App() {
  const store = createStore(reducer, applyMiddleware(thunk));

  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <SplashScreen />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/music">
              <Music />
            </Route>
            <Route exact path="/tv-shows">
              <TvShows />
            </Route>
            <Route exact path="/live-events">
              <LiveEvents />
            </Route>
            <Route exact path="/video-player/:id">
              <VideoPlayer />
            </Route>
            <Route exact path="/tv-episodes/:name">
              <TvShowSeason />
            </Route>
            <Route exact path="/pair">
              <Pair />
            </Route>
            <Route exact path="/account">
              <Account />
            </Route>
            <Route exact path="/single-app">
              <IndividualApp />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
