import React, { useRef, useEffect, useState } from "react";
// import { generateDeviceId } from "../Utils";
import logo from "../assets/images/logo.png";
import login from "../assets/images/login.png";
import { useHistory } from "react-router-dom";
import { storeUser, fetchUserToken } from "../store/actions";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { BACK } from "../constants/keyCodes";
import { handleExitPopupVisibility } from "../store/actions";

function Pair() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const videos = useSelector((state) => state.videos);
  const refs = useRef(null);
  const timer = useRef(null);
  // const deviceId = window.generateDeviceId();
  const deviceId = "";
  const [isSuccessful, setSuccessfull] = useState(false);

  useEffect(() => {
    const token = Cookies.get("authToken");

    if (token && !loading && videos) history.push("/home");
  }, [loading, videos]);

  useEffect(() => {
    refs.current.focus();
    checkDevice();
    timer.current = setInterval(() => {
      checkDevice();
    }, 4000);
    // if (window.history.state !== "backhandler") {
    //   window.history.pushState("backhandler", null, null);
    // }
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const checkDevice = () => {
    fetch(`https://api.nctvn.com/device/${deviceId}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "SUCCESS") {
          dispatch(storeUser(res));
          getUserDetailsWithToken(res.user.email);
          clearInterval(timer.current);
          setSuccessfull(true);
        }
      })
      .catch((e) => {});
  };

  const getUserDetailsWithToken = (email) => {
    dispatch(fetchUserToken(email));
  };

  const handleKeyDown = (e) => {
    switch (e.which) {
      case BACK:
        // if (window.history.state !== "backhandler") {
        dispatch(handleExitPopupVisibility(true));
        // window.history.pushState("backhandler", null, null);
        // }
        // setTimeout(() => {
        //   dispatch(handleExitPopupVisibility(true));
        // }, 500);
        break;
    }
  };

  return (
    <div
      className="w-screen h-screen bg-primary px-28 py-20 flex justify-between overflow-auto outline-none"
      onKeyDown={handleKeyDown}
      tabIndex={0}
      ref={refs}
      id="pair"
    >
      <div>
        <img src={logo} className="w-auto h-40 mb-10" alt="" />

        <p className="mb-8 font-bold text-4xl">Sign in with your browser:</p>

        <div>
          <p className="mb-4 text-2xl">1. Go to nctvn.com/pair</p>
          <p className="mb-4 text-2xl">2. Sign in or create an account</p>
          <p className="mb-8 text-2xl">3. Enter the following code</p>

          <p className="text-5xl font-bold mb-8 tracking-widest">{deviceId}</p>

          <p className="text-xl">
            Note: Please enter the exact code as shown above or the device won't
            pair.
          </p>

          {isSuccessful && (
            <p className="text-4xl text-primary my-7">
              Device Registration Successful.
            </p>
          )}
        </div>
      </div>
      <img src={login} alt="" className="w-auto h-full p-24" />
    </div>
  );
}

export default Pair;
