import React, { useState, useRef } from "react";
import home from "../assets/icons/home.svg";
import shows from "../assets/icons/shows.svg";
import music from "../assets/icons/music_video.svg";
import liveEvents from "../assets/icons/live_events.svg";
import exit from "../assets/icons/exit.svg";
// import account from "../assets/icons/account.svg";
import { LEFT, RIGHT, UP, DOWN, ENTER, BACK } from "../constants/keyCodes";
import { useDispatch, useSelector } from "react-redux";
import { handleExitPopupVisibility } from "../store/actions";
import { useHistory } from "react-router-dom";

function SideMenu({ isMainApp }) {
  const [hasFocus, setHasFocus] = useState(false);
  const [current, setCurrent] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  let menu = useSelector((state) => state.videos?.Menu);
  const MENU_COUNT = menu.length;

  const tvShowsRef = useRef(null);
  const liveEventsRef = useRef(null);
  const musicRef = useRef(null);
  const homeRef = useRef(null);
  // const accountRef = useRef(null);
  const exitRef = useRef(null);

  const handleKeyDownForHome = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (e.which) {
      case ENTER:
        if (isMainApp) history.push("/home");
        else document.getElementById("list-container").focus();
        break;
      case LEFT:
        break;
      case RIGHT:
        document.getElementById("list-container").focus();
        break;
      case UP:
        // exitRef.current.focus();
        break;
      case DOWN:
        if (isMainApp) tvShowsRef.current.focus();
        else exitRef.current.focus();
        break;
      case BACK:
        document.getElementById("list-container").focus();
        break;
    }
  };

  const handleKeyDownForTvShows = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (e.which) {
      case ENTER:
        history.push("/tv-shows");
        break;
      case LEFT:
        break;
      case RIGHT:
        document.getElementById("list-container").focus();
        break;
      case UP:
        homeRef.current.focus();
        break;
      case DOWN:
        musicRef.current.focus();
        break;
      case BACK:
        document.getElementById("list-container").focus();
        break;
    }
  };

  const handleKeyDownForMusic = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (e.which) {
      case ENTER:
        history.push("/music");
        break;
      case LEFT:
        break;
      case RIGHT:
        document.getElementById("list-container").focus();
        break;
      case UP:
        tvShowsRef.current.focus();
        break;
      case DOWN:
        liveEventsRef.current.focus();
        break;
      case BACK:
        document.getElementById("list-container").focus();
        break;
    }
  };

  const handleKeyDownForLiveEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (e.which) {
      case ENTER:
        history.push("/live-events");
        break;
      case LEFT:
        break;
      case RIGHT:
        document.getElementById("list-container").focus();
        break;
      case UP:
        musicRef.current.focus();
        break;
      case DOWN:
        exitRef.current.focus();
        break;
      case BACK:
        document.getElementById("list-container").focus();
        break;
    }
  };

  // const handleKeyDownForAccount = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   switch (e.which) {
  //     case ENTER:
  //       history.push("/account");
  //       break;
  //     case LEFT:
  //       break;
  //     case RIGHT:
  //       document.getElementById("list-container").focus();
  //       break;
  //     case UP:
  //       liveEventsRef.current.focus();
  //       break;
  //     case DOWN:
  //       exitRef.current.focus();
  //       break;
  //     case BACK:
  //       document.getElementById("list-container").focus();
  //       break;
  //   }
  // };

  const handleKeyDownForExit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (e.which) {
      case ENTER:
        dispatch(handleExitPopupVisibility(true));
        break;
      case LEFT:
        break;
      case RIGHT:
        document.getElementById("list-container").focus();
        break;
      case UP:
        if (isMainApp) liveEventsRef.current.focus();
        else homeRef.current.focus();
        break;
      case DOWN:
        // homeRef.current.focus();
        break;
      case BACK:
        document.getElementById("list-container").focus();
        break;
    }
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (e.which) {
      case ENTER:
        if (menu[current]?.name === "Home") {
          history.push("/home");
          document.getElementById("list-container").focus();
        } else if (menu[current]?.name === "EXIT")
          dispatch(handleExitPopupVisibility(true));
        else {
          history.push(`/single-app?menu=${menu[current]?.name}`);
          document.getElementById("list-container").focus();
        }
        break;
      case LEFT:
        break;
      case RIGHT:
        document.getElementById("list-container").focus();
        break;
      case UP:
        setCurrent(current > 0 ? current - 1 : current);
        break;
      case DOWN:
        setCurrent(current < MENU_COUNT ? current + 1 : current);
        break;
      case BACK:
        document.getElementById("list-container").focus();
        break;
    }
  };

  return (
    <nav
      className="nav-container"
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      id="nav"
    >
      {isMainApp ? (
        <>
          <button
            id="home"
            className={`${hasFocus && "pl-7 ml-10 "} nav-link`}
            tabIndex={1}
            onKeyDown={handleKeyDownForHome}
            ref={homeRef}
          >
            <img
              src={home}
              alt="home"
              className={`${!hasFocus && "m-auto"}  `}
            />
            {hasFocus && <span className="ml-6 text-xl font-bold ">Home</span>}
          </button>
          <button
            className={`${hasFocus && "pl-7  ml-10"} nav-link`}
            tabIndex={2}
            onKeyDown={handleKeyDownForTvShows}
            ref={tvShowsRef}
          >
            <img
              src={shows}
              alt="tv shows"
              className={`${!hasFocus && "m-auto"} `}
            />
            {hasFocus && (
              <span className="ml-6 text-xl font-bold">TV Shows</span>
            )}
          </button>

          <button
            className={`${hasFocus && "pl-7  ml-10"} nav-link`}
            tabIndex={3}
            onKeyDown={handleKeyDownForMusic}
            ref={musicRef}
          >
            <img
              src={music}
              alt="Music"
              className={`${!hasFocus && "m-auto"} `}
            />
            {hasFocus && <span className="ml-6 text-xl font-bold">Music</span>}
          </button>

          <button
            className={`${hasFocus && "pl-7  ml-10"} nav-link`}
            tabIndex={4}
            onKeyDown={handleKeyDownForLiveEvents}
            ref={liveEventsRef}
          >
            <img
              src={liveEvents}
              alt="live events"
              className={`${!hasFocus && "m-auto"} `}
            />
            {hasFocus && (
              <span className="ml-6 text-xl font-bold">Live Events</span>
            )}
          </button>

          {/* <button
        className={`${hasFocus && "pl-7  ml-10"} nav-link`}
        tabIndex={5}
        onKeyDown={handleKeyDownForAccount}
        ref={accountRef}
      >
        <img
          src={account}
          alt="account"
          className={`${!hasFocus && "m-auto"} `}
        />
        {hasFocus && <span className="ml-6 text-xl font-bold">Account</span>}
      </button> */}

          <button
            className={`${hasFocus && "pl-7  ml-10"} nav-link`}
            tabIndex={6}
            onKeyDown={handleKeyDownForExit}
            ref={exitRef}
          >
            <img
              src={exit}
              alt="exit"
              className={`${!hasFocus && "m-auto"} `}
            />
            {hasFocus && <span className="ml-6 text-xl font-bold">Exit</span>}
          </button>
        </>
      ) : (
        menu.map((x, idx) => (
          <button
            className={`${hasFocus && "pl-7 ml-10"}  ${
              hasFocus && current == idx
                ? "max-w-80 bg-activeIcon"
                : "bg-transparent w-full"
            } nav-link2 outline-none`}
            tabIndex={idx}
            onKeyDown={handleKeyDown}
            key={x.id}
            id={x.name === "Home" ? "home" : x.id}
          >
            <img
              src={x.icon}
              alt={x?.name}
              className={`${!hasFocus ? "m-auto h-8" : "h-8"} `}
            />
            {hasFocus && (
              <span className="ml-6 text-xl font-bold capitalize">
                {x?.name}
              </span>
            )}
          </button>
        ))
      )}
    </nav>
  );
}

export default SideMenu;
