export const LEFT = 37;
export const UP = 38;
export const RIGHT = 39;
export const DOWN = 40;
export const ENTER = 13;
export const BACK = 4;
// export const PLAY_PAUSE = 85;
// export const PLAY = 126;
// export const PAUSE = 127;
export const PLAY_PAUSE = 179;
// export const PLAY = 179;
// export const PAUSE = 179;
