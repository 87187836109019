import React from "react";
import SideMenu from "./SideMenu";
import { useLocation } from "react-router-dom";
import ExitPopup from "./ExitPopup";
import { useSelector } from "react-redux";

function Layout({ children }) {
  const showExitPopUp = useSelector((state) => state.showExitPopUp);
  const isMainApp = useSelector((state) => state.isMainApp);
  const location = useLocation();

  return (
    <div className="bg-primary w-full h-full text-white">
      {showExitPopUp && <ExitPopup />}
      {!["/", "/pair", "/video-player", "/account"].includes(
        location.pathname
      ) &&
        !location.pathname.includes("/video-player") && (
          <SideMenu isMainApp={isMainApp} />
        )}
      <div>{children}</div>
    </div>
  );
}

export default Layout;
