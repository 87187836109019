import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  playVideo,
  handleExitPopupVisibility,
  setHeroImage,
  selectTvShow,
} from "../store/actions";
import { useHistory, useLocation } from "react-router-dom";
import { LEFT, RIGHT, UP, DOWN, ENTER, BACK } from "../constants/keyCodes";
import Cookies from "js-cookie";

const VideoItem = ({
  videos,
  name,
  type,
  isCurrentPlaylist,
  nextPlaylist,
  previousPlaylist,
  receivedFocusFromMenu,
  totalVideo,
  id,
  perPageVideo,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ref = useRef(null);

  const [current, setCurrent] = useState(0);
  const [gotFocus, setGotFocus] = useState(false);
  const [videolist, setVideoList] = useState([]);

  useEffect(() => {
    setVideoList(videos);
  }, [videos]);

  useEffect(() => {
    if (receivedFocusFromMenu && isCurrentPlaylist && ref.current) {
      setTimeout(() => {
        ref.current.focus();
      }, 100);
    }
  }, [isCurrentPlaylist, receivedFocusFromMenu]);

  useEffect(() => {
    // if (videolist.length > 4) {
    //   if (current == Math.floor(videolist.length / 2)) {
    //     const x = [...videolist, ...videos];
    //     setVideoList(x);
    //   }
    // }
    if (gotFocus) {
      const x = [];
      if (type === "TS")
        for (const key in videolist) {
          if (Object.hasOwnProperty.call(videolist, key)) {
            const element = videolist[key];
            x.push(element[Object.keys(element)[0]].video);
          }
        }

      const heroImage =
        type === "TS"
          ? x[current] && x[current][0].poster
          : videolist[current]?.poster;

      dispatch(setHeroImage(heroImage));
    }
  }, [current, gotFocus]);

  useEffect(() => {
    if (gotFocus) {
      window.onkeydown = (e) => {
        e.preventDefault();

        switch (e.keyCode) {
          case ENTER:
            window.onkeydown = null;
            if (type === "TS") {
              const showName = Object.keys(videolist[current])[0];
              dispatch(selectTvShow(videolist[current]));
              history.push(`/tv-episodes/${showName}`);
            } else {
              dispatch(playVideo(videolist));
              history.push(
                `/video-player/${current}?totalVideo=${totalVideo}&perPageVideo=${perPageVideo}&type=${type}&id=${id}`
              );
            }

            break;
          case LEFT:
            let x = current - 1;
            if (x >= 0) setCurrent((prev) => prev - 1);
            else {
              window.onkeydown = null;
              document.getElementById("home").focus();
            }
            break;
          case RIGHT:
            let y = current + 1;
            if (y < videolist.length) {
              setCurrent((prev) => prev + 1);
              if (
                totalVideo > videolist.length &&
                y === Math.floor(videolist.length / 2)
              ) {
                fetchData();
              }
            }
            break;
          case DOWN:
            nextPlaylist();
            break;
          case UP:
            previousPlaylist();
            break;
          case BACK:
            if (location.pathname.includes("/tv-episodes")) {
              history.goBack();
            } else {
              if (window.history.state !== "backhandler") {
                window.onkeydown = null;
                dispatch(handleExitPopupVisibility(true));
                document.getElementById("list-container").blur();
                // document.getElementById("exit").focus();
                window.history.pushState("backhandler", null, null);
              }
            }
            break;
        }
      };
    }
    return () => {
      window.onkeydown = null;
    };
  }, [gotFocus, current]);

  const fetchData = async () => {
    // const token = JSON.parse(Cookies.get("authToken"));
    // const url = `https://api.nctvn.com/data?page=${Math.ceil(
    //   totalVideo / perPageVideo
    // )}&type=${type}&id=${id}`;
    // const response = await fetch(url, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${token.access_token}`,
    //   },
    // }).then((res) => res.json());
    // const x = [...videolist, ...response.content.video];
    // setVideoList(x);
  };

  let left = -(current + 1) * 22 + 6;
  const videoCarosel = videolist.map((item, index) => {
    left += 22;

    return (
      <div
        className="inline-block h-full w-1/4 absolute px-2 "
        style={{ left: `${left}%`, width: "22%" }}
        key={index}
      >
        <img
          src={
            type === "TS"
              ? item[Object.keys(item)[0]].total_videos &&
                item[Object.keys(item)[0]]?.video[0].poster
              : item.poster
          }
          alt=""
          className={`h-56 w-full inline-block rounded-md shadow-inner ${
            current === index && gotFocus && "border-4 border-selectedItem"
          }`}
        />
      </div>
    );
  });

  return (
    <div className={`mb-4 overflow-hidden relative `}>
      <h4
        className="my-4  text-3xl font-bold pl-2"
        style={{ marginLeft: "6%" }}
      >
        {name}
      </h4>
      <div
        className="relative w-full h-56 outline-none"
        tabIndex={8}
        // onKeyDown={handleKeyDown}
        ref={ref}
        onFocus={() => setGotFocus(true)}
        onBlur={() => setGotFocus(false)}
      >
        {videoCarosel}
      </div>
    </div>
  );
};

export default VideoItem;
